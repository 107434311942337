import React, { useState } from 'react';
import {
  RootDiv,
  GiftAmountTypography,
  ValidityTypography,
  CloseIconButton,
} from './referralBannerDiscount.styles';
import { capitalizeWord } from '@utils';
import ClearIcon from '@mui/icons-material/Clear';
import { ValidateReferenceCouponResponse } from '@queries/referenceCoupon/queries';

interface ReferralBannerDiscountProps {
  referenceCouponValidation: ValidateReferenceCouponResponse['validateReferenceCoupon'];
}

export function ReferralBannerDiscount(props: ReferralBannerDiscountProps) {
  const [isVisible, setIsVisible] = useState(true);
  const {
    name,
    lastName = '',
    code,
    amount,
    type,
    deliveryPeriods,
    deliveryQuantity,
  } = props?.referenceCouponValidation ?? {};

  if (!isVisible) return null;

  if (type === 'DELIVERY_COUPON' && deliveryPeriods === 1) {
    return (
      <RootDiv>
        <GiftAmountTypography>
          Tienes {deliveryQuantity} despacho{deliveryQuantity > 1 ? 's' : ''}{' '}
          gratis, sin mínimo de compra
        </GiftAmountTypography>
        <ValidityTypography>Válido para clientes nuevos</ValidityTypography>
      </RootDiv>
    );
  }
  if (type === 'DELIVERY_COUPON' && deliveryPeriods > 1) {
    return (
      <RootDiv>
        <GiftAmountTypography>
          {deliveryQuantity} despacho{deliveryQuantity > 1 ? 's' : ''} gratis al
          mes, sin mínimo de compra
        </GiftAmountTypography>
        <ValidityTypography>
          Válido para clientes nuevos durante {deliveryPeriods} meses
        </ValidityTypography>
      </RootDiv>
    );
  }

  const message = `${capitalizeWord(name ?? code)}${
    lastName ? ` ${lastName.charAt(0).toUpperCase()}.` : ''
  } te regaló ${amount?.toLocaleString('es-CL', {
    style: 'currency',
    currency: 'CLP',
  })} de descuento!`;
  return (
    <RootDiv>
      <CloseIconButton onClick={() => setIsVisible(false)}>
        <ClearIcon />
      </CloseIconButton>
      <GiftAmountTypography>{message}</GiftAmountTypography>
      <ValidityTypography>Válido para clientes nuevos</ValidityTypography>
    </RootDiv>
  );
}
