import Image from 'next/image';
import Link from 'next/link';
import { getRoute } from '@utils';
import { useWindowSize } from '@hooks/useWindowSize';
import { useSegment } from '@hooks/useSegment';
import {
  RootDiv,
  TextContainer,
  TitleTypography,
  TitleTypographyBold,
  InlineContainer,
  DescriptionTypography,
  ComunesTypography,
  ImageContainer,
  SmallImageContainer,
  LinkStyle,
  ArrowContainer,
  TextArrowDiv,
} from './deliveryZones.styles';

export function DeliveryZones() {
  const { width } = useWindowSize();
  const { track } = useSegment();
  const handleTrack = () => {
    void track('delivery zones view');
  };
  return (
    <>
      <RootDiv>
        <TextContainer>
          <TitleTypographyBold variant="h2">
            DESPACHO GRATIS
          </TitleTypographyBold>
          <InlineContainer>
            <TitleTypography weight={400}>
              en compras sobre $35.000
            </TitleTypography>
          </InlineContainer>
          <DescriptionTypography>
            Para compras menores, el despacho tiene un valor de $3.490. Si
            eliges más de una entrega, el costo de la segunda es de $990.
          </DescriptionTypography>
          <TextArrowDiv>
            <ComunesTypography>
              Despachamos a toda esta{' '}
              <Link {...getRoute('deliveryZones').toModalLink()} passHref>
                <LinkStyle onClick={handleTrack}>zona</LinkStyle>
              </Link>{' '}
            </ComunesTypography>
            {width > 1350 && (
              <ArrowContainer>
                <Image
                  layout="fill"
                  src="assets/images/home/vector50.webp"
                  alt="arrow"
                  objectFit="contain"
                />
              </ArrowContainer>
            )}
          </TextArrowDiv>
        </TextContainer>
        {width >= 992 && (
          <ImageContainer>
            <Image
              layout="responsive"
              src="assets/images/home/dispatchzones.webp"
              alt="deliveryZones"
              priority
              width={803}
              height={871.5}
            />
          </ImageContainer>
        )}
        {width < 992 && (
          <SmallImageContainer>
            <Image
              layout="responsive"
              src="assets/images/home/dispatchzones-cut.webp"
              alt="deliveryZones2"
              priority
              width={803}
              height={871.5}
            />
          </SmallImageContainer>
        )}
      </RootDiv>
    </>
  );
}
