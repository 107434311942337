/*
 * File: date.ts
 * Project: meki
 * File Created: Monday, 26th October 2020 3:31:24 pm
 * Author: Vicente Melin (vicente@inventures.cl)
 * -----
 * Last Modified: Monday, 30th January 2023 5:06:01 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import format from 'date-fns/format';
import differenceInDays from 'date-fns/differenceInDays';
import addDays from 'date-fns/addDays';
import isSaturday from 'date-fns/isSaturday';
import isSunday from 'date-fns/isSunday';
import esLocale from 'date-fns/locale/es';
import getHours from 'date-fns/getHours';
import { getCommuneZone } from './commune';
import { capitalizeWord } from './strings';
import { Console } from './logger';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { addMinutes } from 'date-fns';

const DateFormatter: (input: Date | string) => string = function (rawDate) {
  const date = new Date(rawDate);
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const realDate = new Date(date.getTime() + userTimezoneOffset);
  return format(realDate, 'iiii d MMMM, y', { locale: esLocale });
};

export const daysUntil = (to: Date, from: Date = new Date()) => {
  return differenceInDays(to, from);
};

export const getDateFormatter = DateFormatter;

export const getTimeDifference = (
  to: Date | null,
  from = new Date(Date.now()),
): number => {
  if (!to) return 0;
  return new Date(to).getTime() - new Date(from).getTime();
};

export const getDaysDifference = (
  to: Date | null,
  from = new Date(Date.now()),
): number => {
  if (!to) return 0;
  return differenceInCalendarDays(new Date(from), new Date(to));
};

export const withoutTimezone = (dateString: Date | string): Date => {
  if (!dateString) return dateString as Date;
  const date = new Date(dateString);
  const minutesOffset = date.getTimezoneOffset();
  const dateWithoutOffset = addMinutes(date, minutesOffset);
  return dateWithoutOffset;
};

// format yyyy-MM-dd
const HOLIDAYS: string[] = [
  '2025-01-01',
  '2025-04-18',
  '2025-04-19',
  '2025-05-01',
  '2025-05-21',
  '2025-06-20',
  '2025-06-29',
  '2025-07-16',
  '2025-08-15',
  '2025-09-18',
  '2025-09-19',
  '2025-10-12',
  '2025-10-31',
  '2025-11-01',
  '2025-11-16',
  '2025-12-08',
  '2025-12-14',
  '2025-12-25',
  '2026-01-01',
];
export function addBusinessDays(
  from: Date,
  days: number,
  holidays = HOLIDAYS,
): Date {
  let addedDays = 0;
  let currentDate = from;
  const isBusinessDay = (date: Date) =>
    !(
      isSunday(date) || // its sunday
      isSaturday(date) || // its saturday
      holidays.includes(format(date, 'yyyy-MM-dd'))
    );
  while (days === 0 && !isBusinessDay(currentDate)) {
    currentDate = addDays(currentDate, 1);
  }
  while (addedDays < days) {
    currentDate = addDays(currentDate, 1);
    const currentDateWithoutTimezone = currentDate;
    if (isBusinessDay(currentDateWithoutTimezone)) addedDays += 1;
  }
  return currentDate;
}

// Changes a given function to next wednesday for delivery:
export function changeToNextWednesday(currentDate: Date): Date {
  const daysToAdd = 7 - currentDate.getDay() + 3;
  const newDate = new Date(currentDate.getDate() + daysToAdd); // Duda: esto matará el TZ puesto antes?
  return newDate;
}

export function getMinDeliveryDate(
  communeName?: string,
  currentDate = new Date(Date.now()),
): Date {
  if (communeName) {
    // Find the right delivery day according to commune:
    const communeZone = getCommuneZone(communeName);
    // - CASE: Zone C --> delivery next wednesday
    if (communeZone.zoneType === 'ZONE_C') {
      return changeToNextWednesday(currentDate);
    }
  }
  return addBusinessDays(currentDate, 3);
}

export function disableWeekendsAndHolidays(date: Date, holidays = HOLIDAYS) {
  // format yyyy-MM-dd
  const isWeekend = date.getDay() === 0 || date.getDay() === 6;
  const isHoliday = holidays.includes(format(date, 'yyyy-MM-dd'));
  return isWeekend || isHoliday;
}

export function formatDuration(seconds: number): string {
  const minutes = Math.trunc(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${String(minutes).padStart(2, '0')}:${String(
    remainingSeconds,
  ).padStart(2, '0')}`;
}

export function enableWednesdaysOnly(date: Date) {
  // format yyyy-MM-dd
  const isNotWednesday = date.getDay() !== 3;
  return isNotWednesday;
}

export function next24hrsDay(date = new Date(Date.now())) {
  const next24Hrs = addBusinessDays(date, 1);
  const currentDate = new Date(Date.now());

  if (
    getHours(currentDate) < 8 &&
    !isSaturday(currentDate) &&
    !isSunday(currentDate)
  ) {
    return addBusinessDays(currentDate, 0);
  }

  return next24Hrs;
}
export function formatReadableDate(date: Date) {
  const referenceDay = new Date(Date.now());
  if (referenceDay.toDateString() === date.toDateString()) {
    return 'hoy';
  }
  const differenceInDays = Math.abs(getDaysDifference(referenceDay, date));
  if (differenceInDays === 1) {
    return 'mañana';
  }
  return `el ${extractShortDate(date)}`;
}

export function formatDateDayMonth(date: Date) {
  return format(date, "iiii d 'de' MMMM", { locale: esLocale });
}

export function formatFullReadableDate(date: Date) {
  try {
    return format(date, "iiii d 'de' MMMM, yyyy", { locale: esLocale });
  } catch (e) {
    Console.log('formatFullReadableDate error', { date });
    throw e;
  }
}
export function formatReadableDateWithOutDayName(date: Date) {
  try {
    return format(date, "d 'de' MMMM, yyyy", { locale: esLocale });
  } catch (e) {
    Console.log('formatFullReadableDate error', { date });
    throw e;
  }
}

export function formatDayName(date = new Date(Date.now())) {
  return capitalizeWord(format(date, 'iiii', { locale: esLocale }));
}

export function getWeekdayName(date: string | Date) {
  const days = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];
  return days[new Date(date)?.getDay()];
}

export function extractShortDate(date: string | Date): string {
  try {
    const realDate = new Date(date);
    return format(realDate, 'dd/MM');
  } catch (e) {
    Console.error('extractShortDate unhandled error', { date });
    throw e;
  }
}
export function extractMiddleDate(date: string | Date): string {
  try {
    const realDate = new Date(date);
    return format(realDate, 'dd/MM/yyyy');
  } catch (e) {
    Console.error('extractShortDate unhandled error', { date });
    throw e;
  }
}
